<template>
  <div style="display:flex;justify-content:space-between">
    <el-table :data="nameList" border style="width: 60%">
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <p>{{ scope.row.name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="手机号">
        <template slot-scope="scope">
          <p>
            <i class="el-icon-phone-outline"></i>
            {{ scope.row.phone }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="身份证号">
        <template slot-scope="scope">
          <p>
            <i class="el-icon-postcard"></i>
            {{ scope.row.idCardNumber }}
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="身份证图片"
        prop="img"
        :show-overflow-tooltip="true"
      
      >
        <template slot-scope="scope">
          <p>
            <el-popover placement="right-start" title="图片" trigger="hover">
              <img
                :src="scope.row.idCardFront"
                alt=""
                srcset=""
                style="width: 300px"
              />
              <span
                slot="reference"
                trigger="hover"
                class="el-icon-postcard font-color-brand"
                style="cursor: pointer"
                v-if="scope.row.idCardFront"
                >查看头像面图片</span
              >
            </el-popover>
          </p>
          <p>
            <el-popover placement="right-start" title="图片" trigger="hover">
              <img
                :src="scope.row.idCardBack"
                alt=""
                srcset=""
                style="width: 300px"
              />
              <span
                slot="reference"
                trigger="hover"
                class="el-icon-postcard font-color-brand"
                style="cursor: pointer"
                v-if="scope.row.idCardBack"
                >查看国徽面图片</span
              >
            </el-popover>
          </p>
        </template>
      </el-table-column>-->
      <el-table-column label="银行卡号(二类户)">
        <template slot-scope="scope">
          <div>{{ scope.row.bankCard.bankName }}</div>
          <div>{{ scope.row.bankCard.accountCode }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="注册时间">
        <template slot-scope="scope">{{ scope.row.create_time | dateVal }}</template>
      </el-table-column>-->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bmdList_right">
      <div
        style="display:flex;justify-content:space-between;align-items:center;padding:10px 5px;background-color:#eaf5ff;"
      >
        <span>选择导出信息</span>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >全选</el-checkbox>
      </div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox
          v-for="city in stateOptions"
          :disabled="city.show"
          :label="city.label"
          :key="city.value"
          style="width: 100%;padding: 10px;"
        ></el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  props: {
    nameList: Array
  },
  data () {
    return {
      isIndeterminate: false,
      checkAll: false,
      checkedCities: ['姓名', '手机号', '银行卡号(二类户)', '身份证号'],
      stateOptions: [{ label: '工号', value: 'jobNo', show: false },
      { label: '姓名', value: 'name', show: true },
      { label: '性别', value: 'sex', show: false },
      { label: '籍贯', value: 'native', show: false },
      { label: '民族', value: 'nation', show: false },
      { label: '手机号', value: 'phone', show: true },
      { label: '地址', value: 'address', show: false },
      { label: '出生日期', value: 'birthDay', show: false },
      { label: '身份证号', value: 'idCardNumber', show: true },
      { label: '备注', value: 'remark', show: false },
      { label: '紧急联系人姓名', value: 'sosUserName', show: false },
      { label: '紧急联系人电话', value: 'sosUserPhone', show: false },
      { label: '紧急联系人关系', value: 'sosRelation', show: false },
      { label: '银行卡号(二类户)', value: 'accountCode', show: true },
      { label: '角色', value: 'roles', show: false },
      { label: '注册时间', value: 'create_time', show: false },
      { label: '认证状态', value: 'isAuthenticated', show: false },
        // { label: '用工企业', value: 'workEnterprise', show: false },
      ]
    };
  },
  computed: {
    ...mapState('badMaker', ['nameList'])
  },
  watch: {
    nameList: function (val, old) {

      if (val === old) {
        this.nameList = val;
        const obj = {}
        this.stateOptions.forEach(n => (this.checkedCities.includes(n.label) && (obj[n.label] = n.value)))
        this.setobjList(obj)
      } else {
        this.nameList = val;
        const obj = {}
        this.stateOptions.forEach(n => (this.checkedCities.includes(n.label) && (obj[n.label] = n.value)))
        this.setobjList(obj)
      }

    },

  },
  created () {
    const obj = {}
    this.stateOptions.forEach(n => (this.checkedCities.includes(n.label) && (obj[n.label] = n.value)))
    this.setobjList(obj)

  },
  methods: {
    ...mapMutations('badMaker', ['setNameList', 'setobjList']),
    ...mapActions('badMaker', ['setNameList']),
    del (row) {
      var a = this.nameList.filter(res => res.code !== row.code);
      this.setNameList(a);
      this.$emit('del', a)
    },
    handleCheckAllChange (val) {

      this.checkedCities = val ? this.stateOptions.map(it => it.label) : ['姓名', '手机号', '银行卡号(二类户)', '身份证号']
      console.log(this.checkedCities, val)
      const obj = {}
      this.stateOptions.forEach(n => (this.checkedCities.includes(n.label) && (obj[n.label] = n.value)))
      this.setobjList(obj)
      this.isIndeterminate = !this.isIndeterminate;
    },
    handleCheckedCitiesChange (value) {
      // console.log(this.checkedCities, this.stateOptions)
      const obj = {}
      this.stateOptions.forEach(n => (this.checkedCities.includes(n.label) && (obj[n.label] = n.value)))
      this.setobjList(obj)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.stateOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.stateOptions.length;
    }
  }
};
</script>

<style lang="scss">
.bmdList_right {
  width: 20%;
  // background: yellow;
  border: 1px solid #eee;
}
</style>
